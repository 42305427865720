<div *ngIf="system != null">

    <!--Banner-->
    <div class="row" #banner id="banner">
        <div *ngIf="isOneLine; else monitoringDropdown" class="col-6 monitoringItem">
            <ul class="list-inline">
                <li class="list-inline-item">
                    <a (click)="nav.navigateBack('Company')" [routerLink]="['/']">
                        {{'Unternehmen' | translate}}
                        ({{system.parentLocation.parentCompany.name}})
                    </a>
                </li>
                <li class="list-inline-item"> ></li>
                <li class="list-inline-item">
                    <a (click)="nav.navigateBack('Location')"
                       [routerLink]="['/' + system.parentLocation.parentCompany.name]">
                        {{'Standort' | translate}}
                        ({{system.parentLocation.name}})
                    </a>
                </li>
                <li class="list-inline-item"> ></li>
                <li class="list-inline-item">
                    <a (click)="nav.navigateBack('System')"
                       [routerLink]="['/' + system.parentLocation.parentCompany.name, system.parentLocation.name]">
                        {{'Anlage' | translate}}
                        ({{system.name}})
                    </a>
                </li>
                <li class="list-inline-item"> ></li>
                <li class="list-inline-item">Things</li>
                <li class="list-inline-item">
                    <app-monitoring-search
                        [customerDetail]="data.customer"
                        searchType="thing"
                        [filterCompany]="system.parentLocation.parentCompany.name"
                    ></app-monitoring-search>
                </li>
            </ul>
        </div>
        <div *ngIf="system.parentLocation.parentCompany.name === 'Gebhardt Fördertechnik GmbH'" class="col-2 bannerItem" style="justify-content: end">

            <img class="qricon" [src]="environment.storageUrl + '/icons/QR_icon.svg'"/>

            <div class="hide">
                <ngx-qrcode
                        [elementType]="elementType"
                        [value]="'[' + thingIds + ']'"
                        cssClass="aclass"
                        errorCorrectionLevel="L">
                </ngx-qrcode>
            </div>

        </div>

        <ng-template #qrcode>

        </ng-template>

        <ng-template #monitoringDropdown>
            <div class="col-2">
                <button class="btn btn-secondary" style="margin-left: 35px;" type="button"
                        (click)="nav.navigateBack('System')"
                        [routerLink]="['/' + system.parentLocation.parentCompany.name, system.parentLocation.name]">
                    <img [src]="environment.storageUrl + '/icons/keyboard_backspace-white-18dp.svg'"/>
                </button>
            </div>
            <div class="col-2">
                <app-monitoring-search
                    [customerDetail]="data.customer"
                    searchType="thing"
                    [filterCompany]="system.parentLocation.parentCompany.name"
                ></app-monitoring-search>
            </div>
            <div class="col-6"></div>
        </ng-template>
        <ng-template #dropdown>
            <div class="col-2 dropdown" style="margin-left: -35px; text-align: right;">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a (click)="scrollToAnchor('All')" class="dropdown-item">{{'Allgemein' | translate}}</a>
                    <a (click)="scrollToAnchor('OLS')" *ngIf="system.allThingTypes['OLS'] != null"
                       class="dropdown-item">OLS</a>
                    <a (click)="scrollToAnchor('VCV')" *ngIf="system.allThingTypes['VCV'] != null"
                       class="dropdown-item">VCV</a>
                    <a (click)="scrollToAnchor('MLS')" *ngIf="system.allThingTypes['MLS'] != null"
                       class="dropdown-item">MLS</a>
                    <a (click)="scrollToAnchor('RBG')" *ngIf="system.allThingTypes['RBG'] != null"
                       class="dropdown-item">{{'RBG' | translate}}</a>
                    <a (click)="scrollToAnchor('PRBG')" *ngIf="system.allThingTypes['PRBG'] != null"
                       class="dropdown-item">{{'PRBG' | translate}}</a>
                    <a (click)="scrollToAnchor('PRBG_CUSTOM')" *ngIf="system.allThingTypes['PRBG_CUSTOM'] != null"
                       class="dropdown-item">{{'PRBG' | translate}}</a>
                    <a (click)="scrollToAnchor('GDP')" *ngIf="system.allThingTypes['GDP'] != null"
                       class="dropdown-item">GDP</a>
                    <a (click)="scrollToAnchor('CONVER')" *ngIf="system.allThingTypes['CONVER'] != null"
                       class="dropdown-item">Conver</a>
                    <a (click)="scrollToAnchor('LSC')" *ngIf="system.allThingTypes['LSC'] != null"
                       class="dropdown-item">LSC</a>
                    <a (click)="scrollToAnchor('STW')" *ngIf="system.allThingTypes['STW'] != null"
                       class="dropdown-item">STW</a>
                </div>
            </div>
        </ng-template>
    </div>

    <div class="row">
        <div class="col-lg-12 subheading">
            <h3 id="All">{{'Allgemein' | translate}}</h3>
        </div>

        <div *ngIf="data.activeCompany.name != 'eleven teamsports GmbH'"
             class="col-lg-2 col-md-3 col-sm-4 col-12 monitoring-card">
            <div style="padding-top: 34px;">
            </div>
            <div (click)="nav.createTab('Dashboard', nav.buildSystemUrl(system) + '/dashboard')"
                 class="monitoring-text-card-body">
                <h4 class="title-over-image-center">Dashboard</h4>
            </div>
        </div>

        <div
                *ngIf="data.activeCompany.id === '646f5223c123261ba3700bdd' && data.activeSystem.name !== 'Messeanlage'"
                class="col-lg-2 col-md-3 col-sm-4 col-12 monitoring-card">
            <div style="padding-top: 34px;">
            </div>
            <div (click)="nav.createTab('Testfahrt', nav.buildSystemUrl(system) + '/testdrive')"
                 class="monitoring-text-card-body">
                <h4 class="title-over-image-center">{{'Testfahrt' | translate}}</h4>
            </div>
        </div>

        <div *ngIf="data.activeSystem.PowerBiConfig.Testdrive.ReportId != ''"
             class="col-lg-2 col-md-3 col-sm-4 col-12 monitoring-card">
            <div style="padding-top: 34px;">
            </div>
            <div (click)="nav.createTab('Testfahrt', nav.buildSystemUrl(system) + '/insightdrive')"
                 class="monitoring-text-card-body">
                <h4 class="title-over-image-center">{{'Testfahrt' | translate}}</h4>
            </div>
        </div>

        <div *ngIf="data.activeSystem.PowerBiConfig.Health.ReportId != ''"
             class="col-lg-2 col-md-3 col-sm-4 col-12 monitoring-card">
            <div style="padding-top: 34px;">
            </div>
            <div (click)="nav.createTab('HealthPage', nav.buildSystemUrl(system) + '/healthPbi')"
                 class="monitoring-text-card-body">
                <h4 class="title-over-image-center">HealthPage</h4>
            </div>
        </div>

        <div *ngIf="data.activeSystem.PowerBiConfig.Insight.ReportId != ''"
             class="col-lg-2 col-md-3 col-sm-4 col-12 monitoring-card">
            <div style="padding-top: 34px;">
            </div>
            <div (click)="nav.createTab('Insight', nav.buildSystemUrl(system) + '/insight')"
                 class="monitoring-text-card-body">
                <h4 class="title-over-image-center">GEBHARDT Insight</h4>
            </div>
        </div>


    </div>

    <div *ngIf="system.allThingTypes.has('OLS') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="OLS">One-Level-Shuttle</h3>
        </div>

        <div *ngFor="let ols of system.allThingTypes.get('OLS') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="ols"></app-card-component>
        </div>
    </div>

    <div *ngIf="system.allThingTypes.has('OLSX') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="OLSX">One-Level-Shuttle X</h3>
        </div>

        <div *ngFor="let olsx of system.allThingTypes.get('OLSX') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="olsx"></app-card-component>
        </div>
    </div>

    <div *ngIf="system.allThingTypes.has('OLSE') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="OLSE">One-Level-Shuttle E</h3>
        </div>

        <div *ngFor="let olse of system.allThingTypes.get('OLSE') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="olse"></app-card-component>
        </div>
    </div>

    <div *ngIf="system.allThingTypes.has('OLPS') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="OLPS">Omnipallet</h3>
        </div>

        <div *ngFor="let olps of system.allThingTypes.get('OLPS') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="olps"></app-card-component>
        </div>
    </div>


    <div *ngIf="system.allThingTypes.has('VCV') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="VCV">{{'Heber' | translate}}</h3>
        </div>
        <div *ngFor="let vcv of system.allThingTypes.get('VCV') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="vcv"></app-card-component>
        </div>
    </div>

    <div *ngIf="system.allThingTypes.has('SEQ') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="SEQ">{{'Sequenzer' | translate}}</h3>
        </div>
        <div *ngFor="let seq of system.allThingTypes.get('SEQ') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="seq"></app-card-component>
        </div>
    </div>

    <div *ngIf="system.allThingTypes.has('MLS') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="MLS">Multi-Level-Shuttle</h3>
        </div>
        <div *ngFor="let mls of system.allThingTypes.get('MLS') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="mls"></app-card-component>
        </div>
    </div>

    <div *ngIf="system.allThingTypes.has('RBG') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="RBG">{{'Regalbediengerät' | translate}}</h3>
        </div>
        <div *ngFor="let rbg of system.allThingTypes.get('RBG') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="rbg"></app-card-component>
        </div>
    </div>

    <div *ngIf="system.allThingTypes.has('PRBG') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="PRBG">{{'Paletten RBG' | translate}}</h3>
        </div>
        <div *ngFor="let prbg of system.allThingTypes.get('PRBG') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="prbg"></app-card-component>
        </div>
    </div>

    <div *ngIf="system.allThingTypes.has('PRBG_CUSTOM') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="CTH_CUSTOM">{{'Cheetah Heavy' | translate}}</h3>
        </div>
        <div *ngFor="let prbg_custom of system.allThingTypes.get('PRBG_CUSTOM') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="prbg_custom"></app-card-component>
        </div>
    </div>

    <div *ngIf="system.allThingTypes.has('FTS') === true || system.allThingTypes.has('FTSA') === true || system.allThingTypes.has('FTSF') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="FTS">{{'FTS' | translate}}</h3>
        </div>
        <div *ngFor="let fts of system.allThingTypes.get('FTS') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="fts"></app-card-component>
        </div>
        <div *ngFor="let fts of system.allThingTypes.get('FTSA') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="fts"></app-card-component>
        </div>
        <div *ngFor="let fts of system.allThingTypes.get('FTSF') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="fts"></app-card-component>
        </div>
    </div>


    <div *ngIf="system.allThingTypes.has('BOT') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="BOT">{{'InstaPick Bot' | translate}}</h3>
        </div>
        <div *ngFor="let bot of system.allThingTypes.get('BOT') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="bot"></app-card-component>
        </div>
    </div>

    <div *ngIf="system.allThingTypes.has('GDP') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="GDP">GridPick</h3>
        </div>
        <div *ngFor="let gdp of system.allThingTypes.get('GDP') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="gdp"></app-card-component>
        </div>
    </div>

    <div *ngIf="system.allThingTypes.has('CONVER') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="CONVER">Conver</h3>
        </div>
        <div *ngFor="let conver of system.allThingTypes.get('CONVER') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="conver"></app-card-component>
        </div>
    </div>

    <div *ngIf="system.allThingTypes.has('LSC') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="LSC">Leuze Scanner</h3>
        </div>
        <div *ngFor="let lsc of system.allThingTypes.get('LSC') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="lsc"></app-card-component>
        </div>
    </div>

    <div *ngIf="system.allThingTypes.has('SSAGM') === true" class="row">
        <div class="col-lg-12 subheading">
            <h3 id="SS">Speedsorter</h3>
        </div>
        <div *ngFor="let ssagm of system.allThingTypes.get('SSAGM') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="ssagm"></app-card-component>
        </div>
        <div *ngFor="let ssdtm of system.allThingTypes.get('SSDTM') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="ssdtm"></app-card-component>
        </div>
        <div *ngFor="let sses of system.allThingTypes.get('SSES') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="sses"></app-card-component>
        </div>
        <div *ngFor="let ssif of system.allThingTypes.get('SSIF') | orderBy:'displayName'"
             class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">
            <app-card-component [thing]="ssif"></app-card-component>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4 col-12 monitoring-card" style="padding-top: 24px;">
            <div style="height: 100%">

                <div (click)="nav.createTab('Carrier Übersicht', nav.buildSystemUrl(system) + '/CarrierOverview/1')"
                     class="monitoring-icon-card-body" style="background: #FAFAFA; height: 100%;">

                    <div class="text-center" style="top: 50%">
                        <img [src]="environment.storageUrl + '/img/sscrr.png'" style="width: 45%"
                             class="card-icon img-fluid" alt="Thing Icon"/>
                    </div>
                    <div class="card-label text-center">
                        Carrier Übersicht
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!--    -->
    <!--    <div *ngIf="system.allThingTypes['STOREWARE'] != null" class="row">-->
    <!--        <div class="col-lg-12 subheading">-->
    <!--            <h3 id="STW">StoreWare</h3>-->
    <!--        </div>-->
    <!--        <div *ngFor="let storeware of system.getAll('STOREWARE') | orderBy:'displayName'" class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-12 monitoring-card">-->
    <!--            <app-card-component [thing]="storeware"></app-card-component>-->
    <!--        </div>-->
    <!--    </div>-->

    <div class="gap"></div>
</div>

<!--
<app-monitoring-console *ngIf="data.showMonitoring"
                        [criticalThingToShowInConsole]="monitoring.monitoredThings"></app-monitoring-console>
-->
