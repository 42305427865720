import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, map, Observable, startWith } from 'rxjs';

import { DataProviderService } from '@core-services/data-provider.service';
import { NavigationService } from '@core-services/navigation.service';

@Component({
    selector: 'app-monitoring-search',
    templateUrl: './monitoring-search.component.html',
    styleUrls: ['./monitoring-search.component.scss', '../monitoring.scss']
})
export class MonitoringSearchComponent implements OnInit {
    @Input() public customerDetail: any;
    @Input() public searchType?: SEARCH_TYPE;
    @Input() public filterCompany?: string;
    @Input() public multiSelect?: boolean;

    @Output() multiSelected = new EventEmitter<any[]>();

    public optionsForm = this._formBuilder.group({
        optionGroup: ''
    });

    public filteredOptions: Observable<SearchItem[]>;
    public allSelected = false;

    private searchItems = [];
    private selectedOptions: any[] = new Array<any>();

    constructor(
        public data: DataProviderService,
        public nav: NavigationService,
        private _router: Router,
        private _formBuilder: FormBuilder
    ) {}

    public get searchPlaceholder() {
        if (this.multiSelect) {
            return 'select_things';
        }

        switch (this.searchType) {
            case 'location':
                return 'search_from_location';
            case 'system':
                return 'search_from_system';
            case 'thing':
                return 'search_for_thing';
            default:
                return 'search_all';
        }
    }

    ngOnInit() {
        const slctLocations = { group: '', names: [] };
        const slctComp = { group: '', names: [] };
        const slctSys = { group: '', names: [] };
        const slctThings = [];

        this.customerDetail.forEach((customer) => {
            if (this.filterCompany && customer.name !== this.filterCompany) {
                return;
            }

            const locations: { type: string; name: string; systems: any }[] = Array.from(
                customer.locations.values()
            );
            const systems = locations
                .map((location) =>
                    Array.from(
                        [...location.systems.values()].map((sys) => ({
                            name: `${sys.name} (${customer.name} - ${location.name})`,
                            value: sys
                        }))
                    )
                )
                .flat();

            slctComp.group = customer.type;
            slctComp.names.push({ name: customer.name, value: customer });

            slctLocations.group = locations[0].type;
            slctLocations.names.push(
                ...locations.map((cus) => ({
                    name: `${cus.name} (${customer.name})`,
                    value: cus
                }))
            );

            slctSys.group = SEARCH_TYPE.System.toUpperCase();
            slctSys.names.push(...systems);

            const customerThings = locations
                .map((location) =>
                    Array.from(location.systems.values()).map((system: any) => ({
                        group: `${customer.name} (${location.name} - ${system.name})`,
                        names: system.getAllThings().map((thing) => ({
                            name: `${thing.displayName} (${thing.type})`,
                            thing
                        }))
                    }))
                )
                .flat();

            slctThings.push(...customerThings);
        });

        switch (this.searchType) {
            case 'location':
                this.searchItems = [slctLocations, slctSys, ...slctThings];
                break;
            case 'system':
                this.searchItems = [slctSys, ...slctThings];
                break;
            case 'thing':
                this.searchItems = [...slctThings];
                break;
            default:
                this.searchItems = [slctComp, slctLocations, slctSys, ...slctThings];
                break;
        }

        this.filteredOptions = this.optionsForm.get('optionGroup').valueChanges.pipe(
            startWith(''),
            debounceTime(500),
            map((value: string | { name: string }) => {
                const name = typeof value === 'string' ? value : value?.name;
                return this._filterGroup(name || '');
            })
        );
    }

    public goToItem(event: MatAutocompleteSelectedEvent): void {
        if (this.multiSelect) {
            return;
        }

        const slctCusDetails = event.option.value.value;

        if (slctCusDetails) {
            this._router.navigateByUrl(this.nav.buildCustomerDetailURLs(slctCusDetails));
            return;
        }

        const thing = event.option.value.thing;
        this.nav.navigateToThing(thing);
    }

    public optionClicked(event: Event, option, groupName) {
        event.stopPropagation();
        this.toggleSelection(option, groupName);
    }

    public toggleSelection(option, groupName, allSelected?: boolean) {
        option.selected = !option.selected;

        if (allSelected !== undefined) {
            option.selected = allSelected;
        }

        const i = this.selectedOptions.findIndex(
            (value) => value.thingData?.name === option.name && value.groupName === groupName
        );

        if (option.selected && i < 0) {
            this.selectedOptions.push({ thingData: option, groupName });
            this.multiSelected.emit(this.selectedOptions);

            return;
        }

        this.selectedOptions.splice(i, 1);
        this.multiSelected.emit(this.selectedOptions);
    }

    public selectAll(filter, allSelected) {
        filter.forEach((option) => {
            option.names.forEach((opt) => {
                this.toggleSelection(opt, option.group, allSelected.checked);
            });
        });
    }

    private _filter(opt: any[], value: string): string[] {
        const filterValue = value.toLowerCase();

        return opt.filter((option) => option.name.toLowerCase().includes(filterValue));
    }

    private _filterGroup(value: string) {
        if (value) {
            return this.searchItems
                .map((group) => ({ group: group.group, names: this._filter(group.names, value) }))
                .filter((group) => group.names.length > 0);
        }

        return this.searchItems;
    }
}

export enum SEARCH_TYPE {
    Company = 'company',
    Location = 'location',
    System = 'system',
    Thing = 'thing'
}

export interface SearchItem {
    names: any[];
    group: string[];
}
