import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { GridsterModule } from 'angular-gridster2';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AdminRoutingModule } from './admin-routing.module';
import { MonitoringGatewayComponent } from './monitoring-gateway/monitoring-gateway.component';
import { GatewayStatusPipe } from '../../core/controller/pipes/gateway-status.pipe';
import { MonitoringGatewayPageComponent } from './monitoring-gateway-page/monitoring-gateway-page.component';
import { TileModule } from '../../core/controller/modules/tile.module';
import { CacheComponent } from './cache/cache.component';
import { S7ChangeBoxComponent } from './monitoring-gateway-page/s7-change-box/s7-change-box.component';
import { S7CustomChangeBoxComponent } from './monitoring-gateway-page/s7-custom-change-box/s7-custom-change-box.component';
import { PipesModule } from '../../core/controller/modules/pipes.module';
import { GatewayStatusV2Pipe } from '../../core/controller/pipes/gateway-status-v2.pipe';
import { MonitoringCloudComponent } from './monitoring-cloud/monitoring-cloud.component';
import { MonitoringTimeseriesComponent } from './monitoring-cloud/monitoring-timeseries/monitoring-timeseries.component';
import { LayoutComponent } from '@admin/layout/layout.component';
import { SubComponentModule } from '@core-modules/sub-component.module';
import { SilverModule } from '@silver/silver.module';
import { MonitoringModule } from '@core-modules/monitoring.module';

@NgModule({
    imports: [
        CommonModule,
        AdminRoutingModule,
        TileModule,
        MatProgressBarModule,
        MatDialogModule,
        MatSelectModule,
        MatInputModule,
        FormsModule,
        FontAwesomeModule,
        PipesModule,
        MatTableModule,
        MatIconModule,
        MatTreeModule,
        MatCardModule,
        TranslateModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        GridsterModule,
        SubComponentModule,
        SilverModule,
        MonitoringModule
    ],
    declarations: [
        MonitoringGatewayComponent,
        GatewayStatusPipe,
        MonitoringGatewayPageComponent,
        CacheComponent,
        S7ChangeBoxComponent,
        S7CustomChangeBoxComponent,
        GatewayStatusV2Pipe,
        MonitoringCloudComponent,
        MonitoringTimeseriesComponent,
        LayoutComponent
    ],
    exports: [GatewayStatusPipe, GatewayStatusV2Pipe]
})
export class AdminModule {}
