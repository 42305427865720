import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TranslateModule } from '@ngx-translate/core';

import { BannerModule } from './banner.module';
import { PipesModule } from './pipes.module';

import { CardComponent } from '../../view/card-component/card.component';
import { FavoriteComponent } from '../../view/favorite/favorite.component';
import { MonitoringConsoleComponent } from '../../view/monitoring/monitoring-console/monitoring-console.component';
import { MonitoringCustomerComponent } from '../../view/monitoring/monitoring-customer/monitoring-customer.component';
import { MonitoringLocationComponent } from '../../view/monitoring/monitoring-location/monitoring-location.component';
import { MonitoringSystemComponent } from '../../view/monitoring/monitoring-system/monitoring-system.component';
import { MonitoringThingComponent } from '../../view/monitoring/monitoring-thing/monitoring-thing.component';
import { LocationsComponent } from '../../../packages/bronze/locations/locations.component';
import { CarrierOverviewComponent } from '../../view/monitoring/carrier-overview/carrier-overview.component';
import { MonitoringSearchComponent } from '../../view/monitoring/monitoring-search/monitoring-search.component';

@NgModule({
    declarations: [
        CardComponent,
        FavoriteComponent,
        LocationsComponent,
        MonitoringConsoleComponent,
        MonitoringCustomerComponent,
        MonitoringLocationComponent,
        MonitoringSystemComponent,
        MonitoringThingComponent,
        CarrierOverviewComponent,
        MonitoringSearchComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        LeafletModule,
        TranslateModule,
        BannerModule,
        PipesModule,
        NgxQRCodeModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule
    ],
    exports: [
        CardComponent,
        FavoriteComponent,
        MonitoringConsoleComponent,
        MonitoringCustomerComponent,
        MonitoringLocationComponent,
        MonitoringSystemComponent,
        MonitoringThingComponent,
        CarrierOverviewComponent,
        MonitoringSearchComponent
    ]
})
export class MonitoringModule {}
