<div *ngIf="customerDetail != null">

    <div class="card-header-left">
        <img *ngIf="customerDetail.errorCount > 0 && this.data.showMonitoring"
             class="icon icon-in-card-header"
             [src]="environment.storageUrl + '/icons/error_red.png'"
             [alt]="'Fehler' | translate"
             [title]="'Fehler' | translate"
             style="height: 24px;"/>
        <div *ngIf="customerDetail.errorCount > 0 && this.data.showMonitoring">{{customerDetail.errorCount}}</div>
        <img *ngIf="customerDetail.warningCount > 0 && this.data.showMonitoring"
             class="icon icon-in-card-header"
             [src]="environment.storageUrl + '/icons/warning_yellow.png'"
             [alt]="'Warnung' | translate"
             [title]="'Warnung' | translate"
             style="height: 24px;"/>
        <div *ngIf="customerDetail.warningCount > 0 && this.data.showMonitoring">{{customerDetail.warningCount}}</div>
        <img *ngIf="customerDetail.infoCount > 0 && this.data.showMonitoring"
             class="icon icon-in-card-header"
             [src]="environment.storageUrl + '/icons/info_black.png'"
             [alt]="'Info' | translate"
             [title]="'Info' | translate"
             style="height: 24px;"/>
        <div *ngIf="customerDetail.infoCount > 0 && this.data.showMonitoring">{{customerDetail.infoCount}}</div>
    </div>

    <!--Favoriten-->
    <div *ngIf="!customerDetail.isFavorite; else DetailsFavorite" class="card-header-right icon icon-in-card-header">
        <img [src]="environment.storageUrl + '/icons/util/star-border.svg'" (click)="setFavorite(customerDetail)"
             class="favorite-icon" [alt]="'Favorit aus' | translate" [title]="'Favorit aus' | translate">
    </div>
    <ng-template #DetailsFavorite>
        <div class="card-header-right icon icon-in-card-header">
            <img [src]="environment.storageUrl + '/icons/util/star.svg'" (click)="unsetFavorite(customerDetail)" class="favorite-icon"
                 [alt]="'Favorit an' | translate" [title]="'Favorit an' | translate">
        </div>
    </ng-template>

    <!--Body-->
    <div [routerLink]="detailRouterLink" class="monitoring-text-card-body">
        <div><h4 class="title-over-image-center text-center">{{customerDetail?.name}}</h4></div>
    </div>
</div>

<div *ngIf="thing != null">

    <div class="card-header-left">


            <img *ngIf="thing.parentSystem.parentLocation.parentCompany.name === 'Gebhardt Fördertechnik GmbH'" class="qricon" [src]="environment.storageUrl + '/icons/QR_icon.svg'"/>

            <div *ngIf="thing.parentSystem.parentLocation.parentCompany.name === 'Gebhardt Fördertechnik GmbH'"  class="hide">
                <ngx-qrcode
                        [elementType]="elementType"
                        [value]="thing.id"
                        cssClass="aclass"
                        errorCorrectionLevel="L">
                </ngx-qrcode>
            </div>

        <!--Beide divs sind nur sichtbar wenn es mehr als nur einen Fehler gibt.-->
        <img *ngIf="thing.errorCount > 0 && this.data.showMonitoring"
             class="icon icon-in-card-header"
             [alt]="'Fehler' | translate"
             [title]="'Fehler' | translate"
             [src]="environment.storageUrl + '/icons/error_red.png'"
             style="height: 24px;"/>
        <div *ngIf="thing.errorCount > 0 && this.data.showMonitoring">{{thing.errorCount}}</div>
        <img *ngIf="thing.warningCount > 0 && this.data.showMonitoring"
             class="icon icon-in-card-header"
             [src]="environment.storageUrl + '/icons/warning_yellow.png'"
             [alt]="'Warnung' | translate"
             [title]="'Warnung' | translate"
             style="height: 24px;"/>
        <div *ngIf="thing.warningCount > 0 && this.data.showMonitoring">{{thing.warningCount}}</div>
        <img *ngIf="thing.infoCount > 0 && this.data.showMonitoring"
             class="icon icon-in-card-header"
             [src]="environment.storageUrl + '/icons/info_black.png'"
             [alt]="'Info' | translate"
             [title]="'Info' | translate"
             style="height: 24px;"/>
        <div *ngIf="thing.infoCount > 0 && this.data.showMonitoring">{{thing.infoCount}}</div>

        <div *ngIf="thing.errorCount > 0 && this.data.showMonitoring">
            <div *ngIf="thing.isObserved === false; else ThingObserved">
                <img class="icon icon-in-card-header"
                     (click)="setObserve(thing)"
                     [alt]="'In Konsole anzeigen Icon' | translate"
                     [title]="'In Konsole anzeigen' | translate"
                     [src]="environment.storageUrl + '/icons/unsichtbar-48.png'"
                     style="height: 24px; padding-left: 5px;"/>
            </div>
            <ng-template #ThingObserved>
                <img class="icon icon-in-card-header"
                     (click)="setObserve(thing)"
                     [alt]="'In Konsole anzeigen Icon' | translate"
                     [title]="'In Konsole anzeigen' | translate"
                     [src]="environment.storageUrl + '/icons/sichtbar-48.png'"
                     style="height: 24px; padding-left: 5px;"/>
            </ng-template>
        </div>
    </div>
    <div *ngIf="!thing.isFavorite; else ThingFavorite" class="card-header-right icon icon-in-card-header">
        <img [src]="environment.storageUrl + '/icons/util/star-border.svg'" (click)="setFavorite(thing)" class="favorite-icon"
             [alt]="'Favorit aus' | translate" [title]="'Favorit aus' | translate">
    </div>
    <ng-template #ThingFavorite>
        <div class="card-header-right icon icon-in-card-header">
            <img [src]="environment.storageUrl + '/icons/util/star.svg'" (click)="unsetFavorite(thing)" class="favorite-icon"
                 [alt]="'Favorit an' | translate" [title]="'Favorit an' | translate">
        </div>
    </ng-template>

<!--    If no properies, then schon dark blue, if it has a ControlCabinet, shot CC status, otherwise show thing status-->
    <div (click)="nav.navigateToThing(thing)"
         class="monitoring-icon-card-body" [style.background]="!thing.hasCurrentProperties ? '#7d7d7d' :  (this.thing.controlCabinet.id ? thing.controlCabinet.status.value : thing.status.value)">


        <div class="card-icon-area text-center">
            <img [src]="thing?.icon" class="card-icon img-fluid" alt="Thing Icon"/>
        </div>
        <div class="card-label text-center">
            {{thing?.displayName}}
        </div>
    </div>
</div>
