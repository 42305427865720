import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
    CompactType,
    DisplayGrid,
    GridType,
    GridsterConfig,
    GridsterItem
} from 'angular-gridster2';

import { environment } from '@env/environment';
import thingList from '@thing-view-component/thing-list.json';
import { DataProviderService } from '@core-services/data-provider.service';
import { AuthenticationService } from '@core-services/authentication.service';
import { EventService } from '@core-services/event.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: [
        './layout.component.scss',
        '../../../core/view/tile/tile.scss',
        '../../../../assets/css/template.scss'
    ]
})
export class LayoutComponent implements OnInit {
    public options: GridsterConfig;
    public dashboard: Array<GridsterItem>;
    public dashboardCopy: Array<GridsterItem>;
    public thingLayout: Array<GridsterItem>;

    public myControl = new FormControl<IThingComponent | string>('');
    public filteredOptions: Observable<IThingComponent[]>;

    public selectedThings: any[];

    private thingListData: IThingComponent[];
    private thingIds: string[] = [];
    private url = `${environment.apiUrl}${environment.apiVersion}/thing/setLayout`;

    constructor(
        private router: Router,
        public data: DataProviderService,
        private _http: HttpClient,
        private _auth: AuthenticationService,
        private _snackBar: MatSnackBar,
        private event: EventService
    ) {
        const id = this.router.getCurrentNavigation()?.extras?.state?.id;

        if (!id) {
            return;
        }

        this.thingLayout = this.data.getThing(id)?.layout?.map((thing) => {
            const item = { name: this.genComponentName(thing.type), ...thing };
            const { isShown, ...rest } = item;

            return rest;
        });
    }

    public ngOnInit(): void {
        this.options = {
            gridType: GridType.ScrollVertical,
            maxCols: 25,
            minCols: 25,
            fixedColWidth: 50,
            fixedRowHeight: 50,
            outerMarginBottom: 20,
            compactType: CompactType.None,
            displayGrid: DisplayGrid.Always,
            pushItems: true,
            disablePushOnDrag: true,
            pushResizeItems: true,
            swap: true,
            draggable: {
                enabled: true
            },
            resizable: {
                enabled: true
            },
            itemChangeCallback: this.itemChange.bind(this),
            itemRemovedCallback: this.itemChange.bind(this),
            itemInitCallback: this.itemChange.bind(this)
        };

        this.dashboard = this.thingLayout ?? [];
        this.dashboardCopy = this.thingLayout ?? [];
        this.thingListData = thingList.map((thing) => ({
            name: this.genComponentName(thing.name),
            type: thing.name
        }));

        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map((option) => {
                const name = typeof option === 'string' ? option : option?.type;
                return name ? this._filter(name) : this.thingListData.slice();
            })
        );
    }

    public addItem(): void {
        const componentType = (this.myControl.value as IThingComponent)?.type;

        if (!componentType) {
            return;
        }

        this.dashboard.push({
            x: 0,
            y: 0,
            cols: 3,
            rows: 1,
            type: componentType,
            name: (this.myControl.value as IThingComponent)?.name
        });
        this.myControl.reset();
    }

    public removeItem($event: MouseEvent | TouchEvent, item: GridsterItem): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.dashboard.splice(this.dashboard.indexOf(item), 1);
    }

    public async saveLayout() {
        if (this.thingIds.length <= 0 || this.dashboardCopy.length <= 0) {
            const message = 'Fill layout and choose things to save';
            this._snackBar.open(message, undefined, { duration: 5000 });
            return;
        }

        const reqBody = JSON.stringify({
            thingIds: this.thingIds,
            layout: this.dashboardCopy
        });

        const headers = await this._auth.getDefaultHttpHeaders();

        this._http.post(this.url, reqBody, { headers, responseType: 'text' }).subscribe({
            next: () => {
                const message = 'Succesfully saved!';
                this._snackBar.open(message, 'Reload', {
                    duration: 5000,
                    panelClass: ['success-dialog-snackbar']
                });

                this._snackBar._openedSnackBarRef.onAction().subscribe(() => {
                    location.reload();
                });
            },
            error: (e) => {
                const message = `Error: ${e.error?.error?.message || e}`;
                this._snackBar.open(message, undefined, { duration: 5000 });
            },
            complete: async () => {
                await this.data.getCustomer();
                this.event.init(headers);
            }
        });
    }

    public updateThingIds(items) {
        const groupedByName = items.reduce((acc, item) => {
            const groupName = item.groupName;
            (acc[groupName] = acc[groupName] || []).push(item);
            return acc;
        }, {});

        this.selectedThings = Object.values(groupedByName);
        this.thingIds = items.map((item) => item.thingData.thing.id);
    }

    public displayFn(thing: IThingComponent): string {
        return thing?.name ? thing.name : '';
    }

    private _filter(value: string): IThingComponent[] {
        const filterValue = value.toLowerCase();

        return this.thingListData.filter((option) =>
            option.type.toLowerCase().includes(filterValue)
        );
    }

    private itemChange(): void {
        this.dashboardCopy = this.dashboard.map((item) => {
            if (!item) {
                return item;
            }

            const { name, ...rest } = item;

            return rest;
        });
    }

    private genComponentName(compName: string): string {
        return compName
            .split('-')
            .map((name: string) => name.charAt(0).toUpperCase() + name.slice(1))
            .join(' ');
    }
}

export interface IThingComponent {
    name: string;
    type: string;
}
