<div
    class="row"
    id="banner"
>
    <div class="col-lg-12 monitoringItem">
        <ul class="list-inline">
            <li class="list-inline-item">{{ 'Unternehmen' | translate }}</li>
            <li class="list-inline-item">
                <app-monitoring-search [customerDetail]="data.customer"></app-monitoring-search>
            </li>
        </ul>
    </div>
</div>

<div *ngIf="data.customer !== null">
    <div class="row">
        <div
            *ngFor="let cus of data.customer | orderBy: 'name'"
            class="col-lg-2 col-md-3 col-sm-4 col-12 monitoring-card"
        >
            <app-card-component [customerDetail]="cus"></app-card-component>
        </div>
    </div>
</div>
