<div class="col-lg-12 add-container">
    <form
        class="add-form dense-min"
        [formGroup]="optionsForm"
    >
        <mat-form-field
            appearance="fill"
            subscriptSizing="dynamic"
        >
            <mat-label> Search </mat-label>
            <input
                type="text"
                [placeholder]="searchPlaceholder | translate"
                [attr.aria-label]="'search' | translate"
                matInput
                formControlName="optionGroup"
                [matAutocomplete]="autoGroup"
            />
            <mat-autocomplete
                #autoGroup="matAutocomplete"
                (optionSelected)="goToItem($event)"
            >
                <div *ngIf="multiSelect && (filteredOptions | async) as filter">
                    <mat-checkbox
                        [checked]="allSelected"
                        (change)="selectAll(filter, $event)"
                        (click)="$event.stopPropagation()"
                    >
                        Select All
                    </mat-checkbox>
                </div>
                <mat-optgroup
                    *ngFor="let option of filteredOptions | async"
                    [label]="option.group"
                >
                    <mat-option
                        *ngFor="let opt of option.names"
                        [value]="opt"
                    >
                        <p *ngIf="!multiSelect">
                            {{ opt.name }}
                        </p>

                        <div
                            *ngIf="multiSelect"
                            (click)="optionClicked($event, opt, option.group)"
                        >
                            <mat-checkbox
                                [checked]="opt.selected"
                                (change)="toggleSelection(opt, option.group)"
                                (click)="$event.stopPropagation()"
                            >
                                {{ opt.name }}
                            </mat-checkbox>
                        </div>
                    </mat-option>
                </mat-optgroup>
            </mat-autocomplete>
        </mat-form-field>
    </form>
</div>
