<div class="row">
    <div class="col-lg-12 subheading">
        <h3>{{ 'layout' | translate }}</h3>
    </div>
    <div class="col-lg-12 add-container">
        <form class="add-form">
            <mat-form-field appearance="fill">
                <mat-label> {{ 'add_component' | translate }} </mat-label>
                <input
                    type="text"
                    [placeholder]="'pick_to_add' | translate"
                    [attr.aria-label]="'component' | translate"
                    matInput
                    [formControl]="myControl"
                    [matAutocomplete]="auto"
                />
                <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    (optionSelected)="addItem()"
                >
                    <mat-option
                        *ngFor="let option of filteredOptions | async"
                        [value]="option"
                    >
                        {{ option.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>

        <div class="code-block">
            <app-monitoring-search
                [customerDetail]="data.customer"
                searchType="thing"
                [multiSelect]="true"
                (multiSelected)="updateThingIds($event)"
            >
            </app-monitoring-search>
            <button
                class="btn btn-self-center btn-sm"
                (click)="saveLayout()"
            >
                <mat-icon>save_alt</mat-icon>
            </button>
        </div>
    </div>
    <div class="col-lg-12">
        <p *ngIf="selectedThings?.length">Selected Items to Save:</p>
        <ng-container *ngIf="selectedThings?.length < 4">
            <p
                *ngFor="let things of selectedThings"
                class="inline"
            >
                {{ things[0].groupName }}:
                <ng-container *ngIf="things.length < 4">
                    <span *ngFor="let thing of things; index as i">
                        {{ thing.thingData.name }}
                        <ng-container *ngIf="things.length > 0 && things.length - 1 !== i">
                            ,
                        </ng-container>
                    </span>
                </ng-container>
                <ng-container *ngIf="things.length >= 4">
                    <span>{{ things[0].thingData.name }}(+ {{ things.length - 1 }} things)</span>
                </ng-container>
            </p>
        </ng-container>
        <ng-container *ngIf="selectedThings?.length >= 4">
            <ng-container *ngFor="let things of selectedThings; index as i">
                <p
                    class="inline"
                    *ngIf="i < 4"
                >
                    {{ things[0].groupName }}:
                    <span>({{ things.length }} things)</span>
                </p>
                <p
                    class="inline"
                    *ngIf="i === 4"
                >
                    + {{ selectedThings?.length - 4 }} Companies
                </p>
            </ng-container>
        </ng-container>
    </div>
</div>
<gridster [options]="options">
    <gridster-item
        *ngFor="let item of dashboard"
        [item]="item"
    >
        <div class="button-holder">
            {{ item.name }}
            <button
                mat-mini-fab
                (mousedown)="removeItem($event, item)"
                (touchstart)="removeItem($event, item)"
                class="btn btn-inline btn-sm"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <app-thing-subcomp-parent [widget]="item.type"></app-thing-subcomp-parent>
    </gridster-item>
</gridster>
